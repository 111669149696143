* {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  /* border: 1px dotted red; */
  /* color: rgb(50, 50, 50) */
}

body {
  background-color: whitesmoke;
}

/* dark mode gradient fade vv */
body::before, body::after {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
  transition: opacity .3s ease;
}

body::before {
  /* dark */
  background-image: radial-gradient(circle farthest-corner at -4% -12.9%, rgb(74, 110, 88) 0.3%, hwb(127 12% 81%) 90.2%);
  /* background-image: linear-gradient( 109.6deg,  rgba(61,131,97,1) 11.2%, rgba(28,103,88,1) 91.1% ); */
  opacity: 1;
}

body.change::before {
  opacity: 0;
}

body::after {
  /* light */
  opacity: 0;
  background-image: radial-gradient(circle 2000px at -6.7% 50%, rgba(80, 131, 73, 1) 0%, rgba(140, 209, 131, 1) 26.2%, rgba(178, 231, 170, 1) 50.6%, rgba(144, 213, 135, 1) 74.1%, rgba(75, 118, 69, 1) 100.3%);
}

body.change::after {
  opacity: 1;
}

/* dark mode gradient fade ^^ */

#container, footer {
  width: 100%;
  margin: 0 auto;
}

.nav, .navDark {
  display: flex;
  position: fixed;
  top: 0%;
  /* padding: 0 20px 0 20px; */
  overflow: hidden;
}

.nav, .navDark, footer {
  width: 100%;
  background-color: lightgrey;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  box-shadow: 0 0 10px black;
  /* position: fixed; */
  z-index: 100;
}

.nav h1, .navDark h1 {
  padding: 10px 0;
  font-size: 18px;
  margin-right: 10px;
  font-weight: bold;
  margin-top: 10px;
}

.nav a, .navDark a {
  padding: 10px 0;
  font-size: 17px;
  margin-top: 10px;
  color: rgb(50, 50, 50);
}

.navDark a {
  color: lightgrey;
}

.navDark span {
  color: lightgrey;
}

.nav a, .navDark a, footer {
  text-decoration: none;
  /* color: rgb(50,50,50); */
}

footer {
  padding: 10px 0;
  position: fixed;
  bottom: 0%;
  text-align: center;
  margin: 0;
}

.footerDark, .navDark {
  background-color: rgb(50, 50, 50);
  box-shadow: 0 0 10px lightgrey;
}

.footerDark span {
  color: lightgrey;
}

.navLinksL {
  /* margin-right: 20px; */
  /* padding: 10px; */
  display: flex;
}

.navLinksL a {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 15px;
  font-size: 14px;
}

/* for the tablet breakpoint where it's just icons and hover shows the text */
.navLinksL .hidden {
  display: none;
}

/* hamburger menu */
.navLinksS {
  display: none;
}

/* nav links small screen expanded from hamburger menu */
.navLinksSE, .navLinksSEDark {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 60px;
  right: 0%;
  background-color: lightgray;
  width: 60px;
  align-items: center;
  justify-content: center;
  padding: 20px;
  gap: 30px;
  box-shadow: 0 0 10px black;
}

.navLinksSEDark {
  background-color: rgb(50, 50, 50);
  box-shadow: 0 0 10px lightgrey;
}

.navLinksSE a, .navLinksSEDark a {
  display: flex;
  align-items: flex-end;
  padding: 5px;
  border-radius: 15px;
  font-size: 14px;
  justify-content: center;
}

.section {
  padding-top: 50px;
  width: 100%;
}

main {
  padding: 50px 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.home, .homeDark {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: lightgrey;
  border-radius: 35px;
  padding: 20px;
  margin-top: 50px;
  width: 350px;
}

.homeDark {
  background-color: rgb(50, 50, 50);
  color: lightgrey;
}

#introOne {
  padding: 20px;
  background-color: seagreen;
  border-radius: 25px;
  text-align: center;
  color: lightgrey;
  line-height: 15px;
}

#introOne>img {
  width: 260px;
  border-radius: 10px;
  margin-bottom: 20px;
  margin-left: 0;
}

#introOne h4, #introOne h2 {
  color: lightgrey;
  font-weight: bold;
}

#icons, #iconsDark {
  display: flex;
  justify-content: space-evenly;
  margin: 0 auto
}

#icons img, #iconsDark img {
  margin: 10px 5px 0 5px;
  width: 30px;
}

#introTwo, #introTwoDark {
  padding-left: 20px;
  text-align: center;
  line-height: 1.5;
  width: 350px;
  padding-left: 0;
}

#introTwo h2, #introTwoDark h2 {
  width: 276px;
  margin: 0 auto;
  font-weight: bold;
}

#introTwoDark h2 {
  color: lightgrey;
}

.about h2, .blog h2, .projects h2, .contact h2, .education h2, .tech h2, .aboutDark h2, .blogDark h2, .projectsDark h2, .contactDark h2, .educationDark h2, .techDark h2 {
  background-color: lightgrey;
  text-align: center;
  padding: 10px 0 10px 0;
  box-shadow: 0 0 10px black;
  margin-bottom: 50px;
  width: 100%;
  font-weight: bold;
}

.aboutDark h2, .blogDark h2, .projectsDark h2, .contactDark h2, .educationDark h2, .techDark h2 {
  background-color: rgb(50, 50, 50);
  color: lightgrey;
  box-shadow: 0 0 10px lightgrey;
}

.content, .contentDark {
  margin: 0 auto;
  text-align: center;
  width: 560px;
  background-color: lightgrey;
  padding: 20px;
  border-radius: 50px;
  width: 350px;
}

.contentDark {
  background-color: rgb(50, 50, 50);
  color: lightgrey;
}

.contentDark p {
  color: lightgrey
}

.content h3, .content h4, form h3 {
  font-weight: bold;
}

.content a {
  color: seagreen;
  font-weight: bold;

}

.contentDark a, .contentDark h3, .contentDark h4 {
  color: lightgrey;
  font-weight: bold;
}

.about .content, .aboutDark .contentDark {
  line-height: 1.3;
  text-align: justify;
}

.about p, .aboutDark p {
  padding: 10px;
}

.aboutDark p {
  color: lightgrey;
}

.education li, .educationDark li {
  margin-left: 9.75%;
  list-style: square;
  text-align: left;
}

.educationDark li {
  color: lightgrey
}

.contact {
  margin-bottom: 50px;
}

.row>.form-floating>label {
  left: 10px
}
.lightText {
  color: lightgrey;
}

.custom-input:focus {
  border-color: #80ff8d;
  box-shadow: 0 0 0 0.2rem rgba(0, 255, 13, 0.25);
}

table, th, td {
  border: 1px solid;
  padding: 5px;
}

/* Pokemon vv */
.gameboy {
  display: none;
  flex-direction: column;
  margin: 0 auto;
  padding: 20px;
  background-color: seagreen;
  width: 375px;
  height: 600px;
  border-radius: 30px;
}

.gameboyScr {
  margin: 0 auto 0;
  padding: 35px 45px;
  margin-top: 10px;
  /* width: 76%; */
  height: 56%;

  background-color: rgb(50, 50, 50);
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gameboyScr p {
  font-weight: bold;
  /* margin-bottom: 20px; */
}

.js {
  background-color: whitesmoke;
  background: radial-gradient(circle at center, white 15px, aqua 45px, red 150px);
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 5px;
}

.js img {
  /* display: flex; */
  justify-content: center;
  align-items: center;
  width: 70%;
  height: 70%;
  align-self: center;
  margin-top: 20px;
}

#textBox, #textBox2 {
  /* background-color: red; */
  color: gold;
  -webkit-text-stroke: 1px blue;
  padding: 10px;
  font-weight: bold;
  font-size: 1.1rem;
  font-family: monospace;
}

.pokeName {
  text-transform: capitalize;
}

.gameboy p {
  color: white;
  text-align: center;
  margin-top: -20px;
  font-size: 1.5rem;
}

.C {
  color: red;
}

.O {
  color: purple;
  font-size: 1.75rem;
}

.L {
  color: lime;
}

.O2 {
  color: yellow;
  font-size: 1.75rem;
}

.R {
  color: cyan;
}

.nintendo {
  text-align: center;
  padding: 3px 0;
  text-align: center;
  /* filter:opacity(50%); */
  margin: 5px 120px;
  font-size: 1.25rem;
  border: 2px solid rgb(57, 175, 108);
  border-radius: 25px;
  display: inline-block;
  color: rgb(57, 175, 108);
}

.gameboyCtrl {
  display: flex;
  justify-content: space-between;
  margin: 0px 20px 0px;
}

.dpad {
  width: 139px;
  height: 105px;
  display: flex;
  flex-wrap: wrap;
  /* overflow:visible; */
}

.AB {
  /* width: 200px; */
  height: 75px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 25px
}

.A {
  align-self: flex-start;
}

.sqBtn, .activeBtn, .cirBtn {
  width: 35px;
  height: 35px;
}

.activeBtn, .cirBtn {
  cursor: crosshair;
}

.activeBtn, .cirBtn, .dot {
  background-color: rgb(151, 151, 151);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.cirBtn {
  border-radius: 100px;
}

.ss {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-top: 50px;
}

.ssBtn {
  width: 40px;
  height: 15px;
  background-color: rgb(151, 151, 151);
  border-radius: 16px;
  margin-bottom: 10px;

}

.start, .select {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ssText {
  color: rgb(57, 175, 108);
}

.sillhouette {
  filter: grayscale(100%) brightness(0);
}

.power {
  position: relative;
  top: 80px;
  /* left: 480px; */
  color: whitesmoke;
}

/* #space {
  text-decoration: none;
} */

.darkmodeL, .darkmodeD {
  position: fixed;
  bottom: 10%;
  left: 5%;
  border-radius: 100%;
  border: 0px;
  /* box-shadow: 5px 5px 5px grey; */
  background-color: lightgrey;
  padding: 10px 12px;
}
.darkmodeD {
  /* box-shadow: 5px 5px 5px lightgrey; */
  background-color: rgb(50, 50, 50);
  padding: 10px 14px;
}

/* Pokemon ^^ */


/* Media Queries vv *//* Media Queries vv */

/* Big screen 900-1920 vv */

@media screen and (width >= 901px) {

  .home, .homeDark {
    flex-direction: row;
    width: 580px;
  }

  .homeDark {
    background-color: rgb(50, 50, 50);
    color: lightgrey;
  }

  #introTwo, #introTwoDark {
    margin: 0 auto;
    width: 560px;
  }

  #introTwo h2, #introTwoDark h2 {
    width: 260px;
    margin: 0 auto;
    font-weight: bold;
  }

  #introTwoDark h2 {
    color: lightgrey
  }

  .content, .contentDark {
    width: 580px;
  }

  form textarea {
    width: 425px;
  }

  .navLinksL {
    display: flex;
  }

  .navLinksL .hidden {
    display: inline;
  }

  .education li, .educationDark li {
    margin-left: 25.25%;
  }

  .educationDark li {
    color: lightgrey;
  }

  .gameboy {
    display: flex;
  }

  video {
    display: none;
  }
}

/* Big screen 900-1920 ^^ */

/* Bigger screen 1920+ vv */

@media screen and (width >= 1920px) {

  .nav a, .nav h1, .navDark a, .navDark h1 {
    padding: 18px 0;
    font-size: 50px
  }

  .home, .homeDark {
    flex-direction: row;
    width: 1120px;
    margin-top: 100px;
  }

  #introOne>img {
    width: 520px;
  }

  #introOne {
    font-size: 25px;
  }

  #introTwo h2, #introTwoDark h2 {
    width: 540px;
    margin: 0 auto;
    font-size: 40px;
    font-weight: bold;
  }

  #introTwo img, #introTwoDark img {
    width: 60px;
  }

  .navLinksL a {
    font-size: 20px;
    padding: 20px 35px 20px 20px;
    margin: 0 20px;
  }

  .navLinksL a img {
    height: 150%;
  }

  .content, .contentDark {
    width: 1120px;
  }

  .content p, .contentDark p {
    font-size: 24px;
  }

  .contentDark p {
    color: lightgrey
  }

  .education li {
    margin-left: 38%;
  }

  .educationDark li {
    margin-left: 38%;
  }
}

/* Bigger screen 1920+ ^^ */

/* Tablet screen 625-900 vv */

@media screen and (625px <= width <= 900px) {
  .navLinksL a {
    margin: 0 10px 0 10px;
  }

  .navLinksSE, .navLinksSE a, .navLinksSEDark, .navLinksSEDark a {
    display: none;
  }
}

/* Tablet screen 625-899 ^^ */

/* Phone screen 350-565 vv */

@media screen and (350px <= width <= 565px) {

  /* .nav a, .navDark a {
    font-size: 16px;
  }

  .nav h1, .navDark h1 {
    padding: 18px 0;
    font-size: 20px;
  } */

  #introTwo h2 {
    width: 300px;
    margin: 0 auto
  }

  #introTwoDark h2 {
    width: 300px;
    color: lightgrey;
    margin: 0 auto
  }

  .education li {
    margin-left: 9.75%;
  }

  .educationDark li {
    margin-left: 9.75%;
  }

  video {
    width: 250px;
  }
}

/* Phone screen 350-565 ^^ */

/* Phone screen -350 vv */

@media screen and (width <= 350px) {

  .nav h1, .navDark h1 {
    padding: 18px 0;
    font-size: 20px;
  }

  .home {
    flex-direction: column;
    width: 265px;
  }

  #home, #homeDark {
    width: 265px;
    margin: 65px auto;
  }

  #introOne, #introOneDark {
    width: 225px;
  }

  #introOne>img {
    width: 185px;
  }

  #introTwo, #introTwoDark {
    width: 280px;
  }

  #introTwo h2, #introTwoDark h2 {
    width: 265px;
    margin: 0 auto;
  }

  #introTwoDark h2 {
    color: lightgrey;
  }

  #icons, #iconsDark {
    width: 305px;
  }

  .content, .contentDark {
    width: 265px;
  }

  video {
    width: 200px;
  }

  form textarea {
    width: 215px;
  }

  .education li, .educationDark li {
    margin-left: 5.75%;
  }
}

/* Phone screen -350 ^^ */

/* Hamburger menu all screens below 625 vv*/

@media screen and (width <= 625px) {
  .navLinksL {
    display: none;
  }

  .navLinksS {
    display: block;
    /* padding: 10px; */
  }
}

/* Hamburger menu all screens below 625 ^^ */

/* Nav Links Small Expanded position vv */

@media screen and (350px <= width <= 565px) {
  .navLinksSE, .navLinksSEDark {
    top: 78px;
    top: 60px;
  }
}

@media screen and (305px <= width <= 349px) {
  .navLinksSE, .navLinksSEDark {
    top: 78px;
    /* top: 60px; */
  }
}

@media screen and (180px <= width <= 304px) {
  .navLinksSE, .navLinksSEDark {
    top: 78px;
    /* top: 102px; */
  }
  .nav a, .navDark a {
    font-size: 14px;
  }
}

@media screen and (1px < width <= 181px) {
  .navLinksSE, .navLinksSEDark {
    top: 126px;
  }
  .nav h1, .nav a, .navDark h1, .navDark a {
    font-size: 14px;
  }
}

/* Nav Links Small Expanded position ^^ */

/* Media Queries ^^ *//* Media Queries ^^ */

/* JS & MQ classes */

.shadow {
  position: relative;
  bottom: 10px;
  box-shadow: 5px 5px 5px grey !important;
  transition: 250ms;
}

.shadowNav {
  position: relative;
  bottom: 5px;
  box-shadow: 5px 5px 5px grey;
  color: lightgrey;
  background-color: seagreen;
  border-radius: 40px;
  padding: 10px;
  transition: 500ms;
}

.hide {
  display: none;
}

.navLiHover {
  /* background-color: seagreen; */
  color: lightgrey;
}